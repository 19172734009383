import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Box } from '@mui/material';

import {workexamples} from '../input';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// export async function loader({ params }) {
//   const API = `https://jsonplaceholder.typicode.com/posts/${params.postId}`;

//   try {
//     const response = await fetch(API);
//     if (!response.ok) throw new Error();

//     const data = await response.json();
//     return data;
//   } catch {
//     throw new Response(null, {
//       status: 500,
//       statusText: 'Internal Server Error',
//     });
//   }
// }

export default function WorkPost(props) {

  const location = useLocation();
  const pieces = location.pathname.split(/[\s/]+/)
  const workpost_id = Number(pieces[pieces.length - 1])
  console.log(workpost_id)

  const prev_workpost_id = workpost_id !== 0 ? workpost_id - 1 : workexamples.length - 1
  console.log('prev', prev_workpost_id);
  const next_workpost_id = workexamples.length - 1 === workpost_id ? 0 : workpost_id + 1
  console.log('next', next_workpost_id);


  const workpost = workexamples.find(x => x.id === workpost_id)

  console.log(workexamples.find(x => x.id === location.pathname.split(/[\s/]+/)[pieces.length - 1]))

  return (
    <div className="workpost">
      <Link preventScrollReset={false} to={`/work/${prev_workpost_id}`}>
        <div className="prevWorkPostButton">
          <KeyboardArrowLeftIcon />
        </div>
      </Link>
      <Link preventScrollReset={false} to={`/work/${next_workpost_id}`}>
      <div className="nextWorkPostButton">
          <KeyboardArrowRightIcon />
        </div>
      </Link>
      <h1>{workpost.title}</h1>
      {workpost.title && <h3>{workpost.subtitle}</h3>}
      {workpost.location &&
        <Box my={4}>
          <span className="boldAndLarge">Location:</span> {workpost.location}
        </Box>
      }
      {workpost.customer &&
        <Box my={4}>
          <span className="boldAndLarge">Kunde:</span> {workpost.customer}
        </Box>
      }
      <Box mb={4}>{workpost.description}</Box>
      <div className="video-responsive">
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/${workpost.embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
      {/* <Page title={post.title}>
        {post.body.split('\n').map((paragraph, index) => (
          <p key={`paragraph-${index + 1}`}>{paragraph}</p>
        ))}
      </Page> */}
    </div>
  );
}
