import React from 'react';
import { Box } from '@mui/material';


export default function Clients() {
  return (
    <>
      <div className="headline-background">
        <h1>IDEAL CLIENTS</h1> <br />
        <h3>Das sind Kunden, die wir lieben!</h3>
      </div >
      <div className="workpost">
        <Box my={4}>
          <p>"Großartige Produktionsfirma. Highly professional! Wir geben unsere Bewegtbildproduktionen in keine anderen Hände!" - Unite GmbH</p>
          <p>"5* Service, 5* Qualität, 5* Speed - arbeite immer wieder gerne mit Marcel und seinem Team zusammen. Sympathisch, motiviert, kreativ!" - Fairfleet </p>
          <p>"Tolles Team und professionelle Arbeitsweise! Trotz spontaner Anfrage und knappem Zeitrahmen wurde ein durchdachtes Konzept für ein Recruitingvideo erarbeitet und die Produktion sowie Postproduktion in wenigen Tagen absolut zufriedenstellend umgesetzt." - Kuhn´s Metzgerei</p>
          <Box mt={10}>
            <div className="clients-list">AFFILINET _AGRILUTION GMBH_ALFONS SCHUHBECK_ANDREAS BEGERT_ASSOCIATION FOR VERTICAL FARMING_UMME BLOCK _ANDREASREBERS_BAYERISCHER RUNDFUNK_BLICKPUNKT.POP_BE-SUPPORT GMBH_BLAUER REITER MEDIENAGENTUR_CLUBMASTERS _DRIVEDRESSY GMBH_FAIRFLEET_FANCY FOOTWORK _FOUR MUSIC _FRANGELICO_FEIERWERK MÜNCEN_H&M _HOCHSCHULE FRESENIUS_HOFER_HERTREE_INGRAMMICRO _JAN BLOMQVIST _KINDERSCHUTZ MÜNCHEN_KLOTZ CABLES _KOLLEKTIV MISCHKONSUM_SPORTFREUNDE STILLER_SÜDDEUTSCHE ZEITUNG_TELQUIST _KOOL SAVAS & SIDO ROYAL BUNKER_SESOTEC_KUHNS_MCKINSEY & COMPANY INC._MOBILE DEUTSCHLAND E.V._MUMM _MUNDHAARMONIKA_NEXTFIT GMBH _NICKELODEON_PAUL KOWOL_PAULANER_PERALTA PICTURES _PIXIDA GMBH_PLATTENMOPED PRODUCTIONS_PULS _RACHEL VON GURP JEWLERY_RTL GROUP _SAP_SERVICEPLAN AG_STIMMGOLD CHOR_SHOWMUSIKDE_SKY XFACTOR_SONY MUSIC ENTERTAINMENT_SOMES PROUTS_SPEAKERS EXCELLENCE_STARMELTCLUB_THEKINGSOFCONS_TRION GMBH_UNITE GMBH_WAITERSCLUB_WEEH78 _WIEDEMANN & BERG_ZWOA BIER _3010 BOOKING_XPAY, UVM...</div>
          </Box>
        </Box>
      </div>
    </>
  );
}
