import React from 'react';
import { Grid, Box, Button, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';


// import { Link } from 'react-router-dom';
import image_floorplan from '../../assets/studio_img/grundriss.png';
import image_whereto from '../../assets/studio_img/whereto.png';
import image_studio_chair from '../../assets/studio_img/studio_chair.jpg';
import image_studio_visit from '../../assets/studio_img/studio_visit.png'
import image_studio_facts from '../../assets/studio_img/studio_facts.jpg';
import image_studio_hover1 from '../../assets/studio_img/hover1.jpeg';
import image_studio_hover2 from '../../assets/studio_img/hover2.jpeg';
import image_studio_hover3 from '../../assets/studio_img/hover3.jpeg';
import image_studio_hover4 from '../../assets/studio_img/hover4.jpeg';
import videoBg from '../../assets/studio_video.mp4'
import { apiUrl } from '../../config';
import FullscreenImageWithText from '../../FullscreenImageWithText';
import StudioSectionOne, { sections } from './StudioSectionOne';



const phoneNumber = '+4917666881166';

const factsDownloadUrl = `${apiUrl}/files/IDEALSTUDIOPDF_WEB.pdf`; // URL zur PDF Datei

const idealUrl = 'https://www.idealent.de'

const images = [
  {
    // src: `${apiUrl}/images/studio_img/1.jpeg`,
    src: image_studio_hover1,
    alt: 'Bild 1', title: 'Technische Ausstattung', hoverText: [
      { description: '6 METER HOHLKEHLE:', text: '(STREICHBAR) JE NACH KUNDENWUNSCH, AUSGESTATTET MIT FULL COLOR LED Lichtsetup' },
      { description: 'LICHTSETUP:', text: 'Toplight mit 6x Amaran F22C, Hintergrundbeleuchtung mir 2x Aputure Nova 300W. VIA TABLET STEUERBAR' },
      { description: 'SOUND PERFEKTION:', text: 'YAMAHA HS7 MONITORE (4 STÜCK), ANSPIELBAR VIA BLUETOOTH UND KLINKE' },
      { description: 'FLEXIBLE HINTERGRÜNDE:', text: 'SCHWARZE UND WEIßE VORHÄNGE ZUM ABDUNKELN, DIVERSE PAPIERHINTERGRÜNDE und Syroporwände.' },
      { description: 'EPSON LASER BEAMER ', text: '' },
      { description: 'MICS:', text: 'BIS ZU SECHS SHURE MV6 MIKROFON SETS FÜR PODCAST AUFNAHMEN' },
    ],
  },
  {
    // src: `${apiUrl}/images/studio_img/2.jpeg`, 
    src: image_studio_hover2,
    alt: 'Bild 2', title: 'Einrichtung',
    hoverText: [
      { description: 'WORKSHOP WONDER:', text: 'FLEXIBLE BESTUHLUNGSMÖGLICHKEITEN FÜR WORKSHOPS UND SEMINARE.' },
      { description: 'KONFERENZTISCH', text: 'FÜR BIS ZU 6 PERSONEN, NACH WUNSCH ERWEITERBAR.' },
      { description: '75” OLED FERNSEHER', text: 'FÜR DIE PERFEKTE PRÄSENTATION ODER DEIN BRANDING IM HINTERGRUND.' },
      { description: 'MINI KITCHEN', text: 'MIT MOCCAMASTER & GETRÄNKEKÜHLSCHRANK' },
      { description: 'MAKE UP CORNER', text: 'MIT BELEUCHTETEM SPIEGEL, STEAMER UND FÖN.' }
    ],
  },
  {
    // src: `${apiUrl}/images/studio_img/4.jpeg`, 
    src: image_studio_hover4,
    alt: 'Bild 3', title: 'KOMFORT', hoverText: [
      { description: 'FUSSBODENHEIZUNG:', text: 'AUCH IN DER HOHLKEHLE' },
      { description: 'RAUMTEILER:', text: 'VORHÄNGE FÜR PRIVATSPHÄRE, BZW. DIE TRENNUNG VON STUDIO- UND LOUNGEBEREICH' },
      { description: 'CATERING:', text: 'OB BREAKI, SNACKS, ODER DAS PERFEKTE MEHRGANG DINNER - ES SOLL EUCH AN NICHTS FEHLEN, DENN TEAMLIEBE GEHT BEKANNTLICH DURCH DEN MAGEN.' }
    ],
  },
  {
    // src: `${apiUrl}/images/studio_img/3.jpeg`,
    src: image_studio_hover3,
    alt: 'Bild 4', title: 'EXTRAS', hoverText: [
      { description: 'TECHNIK VERLEIH:', text: 'OB KAMERA SYSTEME, ZUSÄTZLICHES LICHT, ODER SOUND EQUIPMENT, MIETE DEINE TECHNIKPAKETE DIREKT MIT' },
      { description: 'SET DESIGN:', text: 'INDIVIDUELLE SET EINRICHTUNG VIA FTA ZUGANG AUF ANFRAGE MÖGLICH' },
      { description: 'TALENT POOL:', text: 'OB DOP, BELEUCHTER ODER HAIR & MAKE UP ARTIST – WIR VERSTÄRKEN DEIN TEAM AUF ANFRAGE' },
      { description: 'IT’S UP TO YOU:', text: 'MIETE NUR DEN RAUM ODER BUCHE UNSEREN FULL PRODUCTION SERVICE VIA', button: true }
    ],
  },
];

const buttonStyle = {
  borderColor: '#fff',
  color: '#fff',
  margin: '0 8px',
};

const emailHref = `mailto:studio@idealent.de?subject=ANFRAGE%20IDEAL%20STUDIO&body=LIEBES%20IDEAL%20STUDIO%20TEAM%2C%0D%0A%0D%0AICH%20INTERESSIERE%20MICH%20F%C3%9CR%20DIE%20BUCHUNG%20EURES%20FILM-%2C%20FOTO-%2C%20PODCAST-%20UND%20EVENTSTUDIOS.%0D%0A%0D%0A---%0D%0A%0D%0ANAME%3A%0D%0AE-MAIL-ADRESSE%3A%0D%0ATELEFONNUMMER%3A%0D%0A%0D%0A---%0D%0A%0D%0AART%20DES%20PROJEKTS%3A%0D%0A%28FILMPRODUKTION%2C%20FOTOSHOOTING%2C%20PODCAST-AUFNAHME%2C%20EVENT%29%0D%0A%0D%0AGEW%C3%9CNSCHTES%20BUCHUNGSDATUM%3A%0D%0A%0D%0ADAUER%20DER%20BUCHUNG%3A%0D%0A%28STUNDEN%2C%20GANZER%20TAG%2C%20MEHRERE%20TAGE%29%0D%0A%0D%0AANZAHL%20DER%20PERSONEN%3A%0D%0A%28DIE%20AM%20PROJEKT%20TEILNEHMEN%20WERDEN%29%0D%0A%0D%0ABEN%C3%96TIGTE%20AUSSTATTUNG%3A%0D%0A%28KAMERAS%2C%20BELEUCHTUNG%2C%20TON%2C%20HINTERGRUNDKULISSEN%29%0D%0A%0D%0ASPEZIELLE%20ANFORDERUNGEN%20ODER%20W%C3%9CNSCHE%3A%0D%0A%28CATERING%2C%20TECHNISCHE%20UNTERST%C3%9CTZUNG%2C%20SPEZIELLES%20EQUIPMENT%29%0D%0A%0D%0ABUDGET%3A%0D%0A%28OPTIONAL%2C%20UM%20EIN%20PASSENDES%20ANGEBOT%20ZU%20ERSTELLEN%29%0D%0A%0D%0A---%0D%0A%0D%0AGIBT%20ES%20NOCH%20ZUS%C3%84TZLICHE%20INFORMATIONEN%20ODER%20SPEZIFISCHE%20ANFORDERUNGEN%2C%20DIE%20F%C3%9CR%20DEIN%20PROJEKT%20WICHTIG%20SIND%3F%0D%0A%0D%0AVIELEN%20DANK%20F%C3%9CR%20DEINE%20ANFRAGE.%20WIR%20WERDEN%20UNS%20SO%20SCHNELL%20WIE%20M%C3%96GLICH%20BEI%20DIR%20MELDEN%2C%20UM%20ALLE%20DETAILS%20ZU%20BESPRECHEN%20UND%20EIN%20MA%C3%9FGESCHNEIDERTES%20ANGEBOT%20ZU%20ERSTELLEN.%0D%0A%0D%0AIDEAL%20STUDIO%20BY%20IDEAL%20ENT.%0D%0AREGERPLATZ%204%0D%0A81541%20M%C3%9CNCHEN%0D%0AWWW.IDEALENT.DE`;

const ideal_for = [
  {
    items: [
      "VIDEOPRODUKTIONEN", "FOTOSHOOTINGS", "INTERVIEWS", "MUSIKVIDEOS", "WERBUNG", "PRODUCT SHOOTINGS", "LIVE STREAMING"
    ]
  },
  {
    items: [
      "PODCASTS", "VIDEOPODCASTS", "O TON AUFNAHMEN", "STREAMING"
    ]
  },
  {
    items: [
      "COACHING", "WORKSHOPS", "BESPRECHUNGEN", "LESEPROBEN", "AUSSTELLUNGEN", "KLEINERE EVENTS BIS ZU 30 PERSONEN"
    ]
  }
];

// export async function loader({ params }) {
//   const API = `https://jsonplaceholder.typicode.com/posts/${params.postId}`;

//   try {
//     const response = await fetch(API);
//     if (!response.ok) throw new Error();

//     const data = await response.json();
//     return data;
//   } catch {
//     throw new Response(null, {
//       status: 500,
//       statusText: 'Internal Server Error',
//     });
//   }
// }

export default function Studio() {
  // const [videoLoaded, setVideoLoaded] = useState(false);

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   const videoElement = document.getElementById('background-video');
  //   videoElement.addEventListener('loadeddata', () => {
  //     setVideoLoaded(true);
  //   });

  //   return () => {
  //     videoElement.removeEventListener('loadeddata', () => {
  //       setVideoLoaded(true);
  //     });
  //   };
  // }, []);

  // function importAll(r) {
  //   return r.keys().map(r);
  // }

  // const images = importAll(require.context('../assets/studio_img', false, /\.(png|jpe?g|svg)$/));

  return (
    <div>
      <div>
        <div className='contents'>
          {/* {!videoLoaded && <div style={{ width: '100%', height: '100vh', backgroundColor: '#000' }}></div>} */}
          <video id="background-video" src={videoBg} autoPlay loop muted playsInline webkit-playsinline="true" />
          <div className='content'>
            <Typography variant="h1" mx={4} sx={{ fontSize: { xs: 'h2.fontSize', sm: 'h1.fontSize', md: 'h1.fontSize' } }}>IDEAL STUDIO. YOUR IDEAL SPACE.</Typography>
            {/* <h1>Bilder. Die Bewegen.</h1> */}
            <Typography variant="body1" mx={4} mb={16} mt={4}>MULTIFUNKTIONALER KREATIVRAUM IN MÜNCHEN. FÜR FOTO, VIDEO, PODCAST & EVENTS.
              IT´S UP TO YOU:  MIETE NUR DEN RAUM ODER BUCHE UNSEREN FULL PRODUCTION SERVICE.
            </Typography>
            {/* <Link preventScrollReset={false} to={`/contact`}> */}
            <Button style={buttonStyle} variant="outlined">
              <a style={{ textDecoration: 'none', color: 'inherit' }} href={emailHref}>
                <Typography variant="body1">Klingt Ideal?</Typography>
              </a>
            </Button>
            {/* </Link> */}
            {/* <Box mt={8}>
              <ExpandMoreIcon />
            </Box> */}
          </div>
        </div>
        <Box display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ height: isMobile ? 'auto' : '100vh', minHeight: '100vh', p: 2, overflowY: isMobile ? 'auto' : 'hidden' }}
        // sx={{ height: '100vh' }}
        >
          <Typography variant="h2" m={4} display="flex" justifyContent="center">BEI UNS FINDEST DU DEN RAUM, UM DEINE KREATIVEN IDEEN ZUM LEBEN ZU ERWECKEN:
          </Typography>
          {/* The Studio / Podcast Lounge / Round Table */}

          <Box my={4} display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between">
            {sections.map((section, index) => (
              <StudioSectionOne
                key={index}
                title={section.title}
                subtitle={section.subtitle}
                items={section.items}
                boxProps={section.boxProps}
                isMobile={isMobile}
              />
            ))}
          </Box>
        </Box>

        {/* IDEAL FÜR */}
        <FullscreenImageWithText
          imageSrc={image_studio_chair} // Ersetze dies durch den Pfad zu deinem Bild
          content={
            <Box>
              <Typography m={2} variant="h2">IDEAL FÜR:</Typography>
              {/* <Typography variant="subtitle1">Untertitel oder weiterer Text hier</Typography> */}
              <Box
                py={0}
                mt={{ xs: 0, sm: 2 }}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}  // Stack items on mobile and row on larger screens
                justifyContent="space-between"
              >
                {ideal_for.map((category, categoryIndex) => (
                  <Box key={categoryIndex} flex={1} mb={{ xs: 0, sm: 0 }} py={0}> {/* Add margin-bottom on mobile */}
                    <List sx={{ color: '#fff', paddingTop: 0, paddingBottom: 0 }}>
                      {category.items.map((item, itemIndex) => (
                        <ListItem key={itemIndex}>
                          <ListItemText
                            primary={<Typography variant="h5">• {item}</Typography>}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </Box>
            </Box>
          }
          heightXs="140vh"
        />

        {/* Erfahrung */}
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2 }}>
          <Box>
            <Typography variant="h2" sx={{ mx: 4, textAlign: 'center', fontSize: { xs: 'h4.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' } }}>
              PROFITIERE DABEI VON UNSERER LANGJÄHRIGEN ERFAHRUNG ALS PRODUKTIONSFIRMA, KREATIVAGENTUR & TALENT-POOL.
              WELCHE VISION KÖNNEN WIR GEMEINSAM UMSETZEN?
            </Typography>
            <Box sx={{ mx: { xs: 2, sm: 4, md: 8 }, mt: 4, display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: { xs: 'space-between', sm: 'center' }, alignItems: 'center' }}>
              <Box mb={isMobile ? 4 : {}}>
                <Button style={buttonStyle} variant="outlined" >
                  <a style={{ textDecoration: 'none', color: 'inherit' }} href={emailHref}>
                    <Typography variant="body1">E-Mail</Typography>
                  </a>
                </Button>
              </Box>
              <Box mb={isMobile ? 4 : {}}>
                <Button style={buttonStyle} variant="outlined" href={`tel:${phoneNumber}`}>
                  Telefon
                </Button>
              </Box>
              <Button
                style={buttonStyle}
                variant="outlined"
                href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                  `Liebes Ideal Studio Team 📹,\n\nIch interessiere mich für die Buchung eures Film-, Foto-, Podcast- und Eventstudios.\n\n- Name: \n- E-Mail: \n- Telefon: \n- Projektart: (Filmproduktion, Fotoshooting, Podcast, Event) \n- Buchungsdatum/Dauer: \n- Anzahl Personen: \n- Budget: (Optional) \n\nGibt es noch weitere Informationen oder Anforderungen?`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </Button>
            </Box>
          </Box>
        </Box>



        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'space-between' : 'center'} mb={isMobile ? 4 : 8} >
          <img
            height="auto"
            width={isMobile ? '100%' : '50%'}
            src={image_floorplan}
            alt="Grundriss"
          />
          <a
            href="https://maps.app.goo.gl/apSHYAdByNe7a3ZeA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              height="auto"
              width={isMobile ? '100%' : '100%'}
              src={image_whereto}
              alt="Wegbeschreibung"
            />
          </a>
          {/* <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2663.2707111172635!2d11.585066077174252!3d48.12430257124205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ddf7a6b2498ff%3A0xd05f03262c5309f7!2sRegerpl.%204%2C%2081541%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1709240342352!5m2!1sde!2sde"
            width="50%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          /> */}
        </Box>
        <Typography variant="h2" sx={{ mx: 4, textAlign: 'center', fontSize: { xs: 'h3.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' } }}>
          MITTEN IN MÜNCHEN. MÜNCHEN IM HERZEN.
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mx={8} mb={16} mt={4} style={{ textAlign: 'center' }}>
          <Typography variant="body1">
            MULTIFUNKTIONALER KREATIVRAUM IN MÜNCHEN. FÜR FOTO, VIDEO, PODCAST & EVENTS. DIREKT AM REGERPLATZ. BUS, TRAM UND S-BAHN IN UNMITTELBARER NÄHE.
          </Typography>
        </Box>

        <FullscreenImageWithText
          imageSrc={image_studio_visit} // Ersetze dies durch den Pfad zu deinem Bild
          content={
            <Box>
              <Typography variant="h2" sx={{ textAlign: 'center', fontSize: { xs: 'h3.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' } }}>BESICHTIGUNGSTERMIN?</Typography>
              <Typography variant="body1"> KOMM VORBEI UND WIR PLANEN GEMEINSAM, ODER KLICK HIER: </Typography>
              <Box mt={8}>
                <Button style={buttonStyle} variant="outlined">
                  <a href={factsDownloadUrl} download="filename.pdf" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Download
                  </a>
                </Button>
              </Box>
            </Box>
          }
          heightXs="100vh"
          noCenter={false}
        />
        <Grid container spacing={0}>
          {images.map((image, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover .hoverText': {
                    opacity: !isMobile ? 1 : 1,
                  },
                  '&:hover .image': {
                    opacity: !isMobile ? 0.5 : 1,
                  }
                }}
              >
                <Box my={8}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      position: 'relative',
                      top: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                      width: '100%',
                      zIndex: 1,
                    }}
                  >
                    {image.title}
                  </Typography>
                </Box>
                <img
                  src={image.src}
                  alt={image.alt}
                  style={{
                    width: '100%',
                    height: 'auto',
                    transition: 'opacity 0.3s',
                    display: 'block',
                  }}

                />
                <Box
                  mt={{ xs: 14, sm: 16, md: 20 }}
                  className="hoverText"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    borderRadius: 1,
                    opacity: 0,
                    transition: 'opacity 0.3s',
                    textAlign: 'left',
                    width: '100%',
                    height: {
                      xs: '100%',  // Höhe für xs-Breakpoint
                      sm: '100%',   // Höhe für sm-Breakpoint
                      md: '100%'    // Höhe für md-Breakpoint
                    },
                    zIndex: 2,
                    pointerEvents: 'none', // Ensures that the hover effect is only applied to the image
                    // overflow: 'auto',
                  }}
                >
                  <ul style={{ paddingLeft: '1em', margin: '72px 0 0 16px', listStylePosition: 'outside' }}>
                    {image.hoverText.map((item, idx) => (
                      <li key={idx} style={{ marginBottom: '0.5em' }}>

                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', sm: '1rem', md: '1.4rem' }, }}
                        >
                          {item.description}
                        </Typography>

                        {' '}
                        <Typography
                          variant="body1"
                          component="span"
                          sx={{ fontSize: { xs: '0.8rem', sm: '1rem', md: '1.4rem' } }}
                        >
                          {item.text} {item.button === true ? <Button style={buttonStyle} variant="outlined">
                            <a style={{ textDecoration: 'none', color: 'inherit' }} href={emailHref}>
                              <Typography variant="body1">E-MAIL</Typography>
                            </a>
                          </Button> : null}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <FullscreenImageWithText
          imageSrc={image_studio_facts} // Ersetze dies durch den Pfad zu deinem Bild
          content={
            <Box>
              <Typography m={4} variant="body1" sx={{ textAlign: 'left', fontSize: { xs: '1rem', sm: '1rem', md: '1.4rem' }, width: { xs: '60%', sm: '40%', md: '30%' } }}>IDEAL STUDIO IST UNSER KLEINES RAUMWUNDER,
                DASS WIR UNS SELBST IMMER GEWÜNSCHT HABEN &
                WIR SIND:
              </Typography>
              <Typography width="30%" m={4} variant="body1" sx={{ textAlign: 'left', fontSize: { xs: '1rem', sm: '1rem', md: '1.4rem' }, width: { xs: '60%', sm: '40%', md: '30%' } }}>
                IDEAL ENTERTAINMENT - PRODUKTIONSFIRMA,
                KREATIVAGENTUR & TALENT-POOL.
              </Typography>
              {/* <Typography variant="body1">               IDEAL ENTERTAINMENT - PRODUKTIONSFIRMA,
                KREATIVAGENTUR & TALENT-POOL. </Typography> */}
              <Box mt={8} justifyContent="center" display="flex" width="100vw">
                <Button style={buttonStyle} variant="outlined" href={idealUrl}>
                  IdealEnt.de
                </Button>
              </Box>
            </Box>
          }
          heightXs="100vh"
          noCenter={true}
        />
      </div >
    </div >
  );
}
