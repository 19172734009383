import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import {pressexamples} from '../input';

export default function Press() {


  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(require.context('../assets/press_img', false, /\.(png|jpe?g|svg)$/));
  const Image = ({ image, index }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <>
        {/* <p>{workexamples.find(x => x.id === index)?.id}</p> */}
        <Link preventScrollReset={false} to={`/work/${index}`}>
          <div
            onMouseLeave={() => setIsHovered(false)}
            onMouseEnter={() => setIsHovered(true)}
            className="overlay-gallary"
            style={{ display: isHovered ? 'none' : 'block' }}
          >
          </div>
          <div
            onMouseEnter={() => setIsHovered(isHovered)}
            onMouseLeave={() => setIsHovered(!isHovered)}
            className="overlay-work-gallary"
            style={{ display: isHovered ? 'block' : 'none' }}
          >
            <span className="title-gallary">{pressexamples.find(x => x.id === index)?.title}</span>
          </div>

          <img
            key={index}
            src={image}
            alt={`${index}`}
            className="gallery-image picture"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Link>
      </>
    )
  }

  return (
    <>
      <div className="headline-background">
        <h1>PRESS</h1>
      </div>
      <div className="workpost">
        <div className="gallery-press">
          {images.map((image, index) => {
            return (
              <div className="picture-container">
                <Image image={image} index={index} />
              </div>
            )
          })}
        </div>
        <Box my={4}>
          {/* <span className="boldAndLarge">Under Construction</span> */}
          <div className="gallery">
          </div>
        </Box>
      </div>
    </>
  );
}
