import React from 'react';
import { Box } from '@mui/material';

const FullscreenImageWithText = ({ imageSrc, content, heightXs = '150vh', noCenter}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        height: { xs: heightXs, sm: '100vh' },
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={imageSrc}
        alt="Background"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      <Box
      sx={{
        ...(noCenter
          ? {
              color: 'white',
              textAlign: 'left',
              width: '90vw',
            }
          : {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              textAlign: 'center',
              width: '90vw',
            }),
      }}
      >
{content}
      </Box>
    </Box>
  );
};

export default FullscreenImageWithText;
