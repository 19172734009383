import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useMediaQuery, Typography } from '@mui/material';

import studio from '../assets/studio_logo.png'
import logo from '../assets/logo.png'


export default function Home() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className="fixed-wrapper">
      <Box sx={{ height: '100vh' }} display="flex" >
        <Box display="flex" alignItems="center" justifyContent={isMobile ? 'flex-start' : 'center'} flexDirection={isMobile ? 'column' : 'row'}>
          <Box m={isMobile ? 0 : 8} mb={isMobile ? 0 : 8} sx={{ width: isMobile ? '100%' : '50%' }}>
            <Link to={`/studio`} style={{ textDecoration: 'none' }}>
              <Box m={isMobile ? 8 : 0} style={{ textAlign: 'center', position: 'relative' }}>
                <img
                  src={studio}
                  alt="Studio"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Box>
            </Link>
            <Typography ml={isMobile ? 8 : 0} mt={2} variant="h4" display="flex" justifyContent={isMobile ? "flex-start" : "center"}>Studio</Typography>

          </Box>
          <Box m={isMobile ? 0 : 8} sx={{
            width: isMobile ? '100%' : '50%'
          }}>
            <Link to={`http://idealent.co`} style={{ textDecoration: 'none' }}>
              <Box m={isMobile ? 8 : 0} style={{ textAlign: 'center', position: 'relative' }}>
                <img
                  src={logo}
                  alt="Ideal Ent"
                  style={{ maxWidth: '100%', height: 'auto', marginTop: '12px', marginBottom: '12px' }} // 12px margin for virtual height
                />
              </Box>
            </Link>
            <Typography ml={isMobile ? 8 : 0} mt={2} variant="h4" display="flex" justifyContent={isMobile ? "flex-start" : "center"}>PRODUCTION COMPANY</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
