import React from 'react';
import { Box } from '@mui/material';

export default function MakingOf() {
  return (
    <>
      <div className="headline-background">
        <h1>MAKING OF</h1>
      </div>
      <div className="workpost">
        <Box my={4}>
          <span className="boldAndLarge">Under Construction</span>
          <div className="gallery">
          </div>
        </Box>
      </div>
    </>
  );
}
