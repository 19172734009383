import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Divider, ListItemButton, Toolbar, Typography, Button, IconButton, Drawer, List, Box, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from './assets/logo.png';
import studiologo from './assets/studio_logo.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const drawerWidth = 240;
const navItems = ['studio', 'work', 'clients', 'press', 'making_of', 'contact', 'impressum'];

export default function NavBar() {
  const location = useLocation();

  let logo_icon = null;

  if (location.pathname === '/studio') {
    logo_icon = studiologo;
  } else if (location.pathname !== '/') {
    logo_icon = logo;
  }

  // const logo_icon = location.pathname === '/studio' ? studiologo : logo;
  // console.log(location.pathname)

  const [prevScrollPos, setPrevScrollPos] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [prevScrollPos, setPrevScrollPos] = React.useState(window.scrollY);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);
      setPrevScrollPos(currentScrollPos);
    };

    if (location.pathname === '/work' || location.pathname === '/studio') {
      window.addEventListener('scroll', handleScroll);
    } else setVisible(true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, visible, location.pathname]);

  // console.log(prevScrollPos)

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: "black", color: 'white', height: '100vh', overflowY: 'auto' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Ideal
        {/* <Box display="flex" alignItems="center">
              <Box mx={2}>
                <FacebookIcon />
              </Box>
              <Box>
                <InstagramIcon />
              </Box>
              <Box mx={2}>
                <EmailIcon />
              </Box>
            </Box> */}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <Link key={item} to={`${item}`} style={{ textDecoration: 'none', color: 'white' }}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (

    <>
      <AppBar
        component="nav"
        sx={{
          backgroundColor: visible && (location.pathname === '/work' || location.pathname === '/studio') ? 'transparent' : 'black', boxShadow: "none",
          transition: 'background-color 0.3s',
          // position: location.pathname === '/work' || location.pathname === '/studio' ? 'fixed' : 'fixed'
        }}
      >
        <Toolbar >
          {logo_icon !== null &&
            <Link preventScrollReset={false} to={`/`}>
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'block', md: 'block' } }}
              >
                <img src={logo_icon} alt={''} width={"130px"} style={{ marginTop: 10 }} />
              </Typography>
            </Link>
          }
{/* Für neue Page */}
          {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            {navItems.map((item) => (
              <Link key={item} to={`${item}`}>
                <Button sx={{ color: '#fff' }}>
                  {item}
                </Button>
              </Link>
            ))}

            <Box display="flex" alignItems="center">
              <Box mx={2}>
                <FacebookIcon />
              </Box>
              <Box>
                <InstagramIcon />
              </Box>
              <Box mx={2}>
                <EmailIcon />
              </Box>
            </Box>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      {/* <Drawer
        anchor="right"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer> */}
    </>
  );
}


