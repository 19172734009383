import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  // palette: {
  //   primary: '#fff',
  //   secondary: '#000'
  // },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    h5: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: 'Bebas Neue, sans-serif',
      textTransform: 'uppercase',
    },
    body1: {
      textTransform: 'uppercase',
    },
    body2: {
      textTransform: 'uppercase',
    },
    subtitle1: {
      textTransform: 'uppercase',
    },
    subtitle2: {
      textTransform: 'uppercase',
    },
    button: {
      textTransform: 'uppercase',
    },
    caption: {
      textTransform: 'uppercase',
    },
    overline: {
      textTransform: 'uppercase',
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
          <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
