export const workexamples = [
    {
        "id": 0,
        "title": "BAVARIAN EXPORT SESSION | SHOWCASE @ Reeperbahn Festival 2020",
        "subtitle": "Bavarian Export Session for Reeperbahnfestival 2020 - Bands: Umme Block, Elena Rud, LonelySpring, Telquist.",
        "description": "Aus Bayern nonstop in die Hansestadt und von da hinaus in die Welt: Im Rahmen der Bavarian Export Session werden vier junge Musikprojekte auf dem Reeperbahn Festival vorgestellt. Die Session wird von dem VPBy (Verband für Popkultur in Bayern e.V.) präsentiert, einem der führenden Förderer hiesiger Popmusik, der über 140 Clubs, Events, Festivals usw. unter seinem Dach vereint. In der dieses Jahr digital veranstalteten Edition präsentieren wir den passionierten Pop-Punk von Lonely Spring, inklusive Ganzkörpereinsatz, sowie den süßlich verqualmten Slackersound eines Telquist. Es gibt wunderbar sequenzierte Electronica zwischen Trip-Hop, House und Synthkaskaden von Umme Block, aber auch umwerfenden „Melancholic Love Shit“ von Songwriterin Elena Rud, deren einmalige Stimme jetzt schon auf Weltklasselevel rangiert. Also für alle was dabei. Wir empfehlen: Weizen kaltstellen und die Show genießen.",
        "customer": "VPBY - Verband für Popkultur in Bayern e.V. - www.popkultur.bayern",
        "location": "Backstage Werk München",
        "comments": "bratan",
        "ytLink": "https://www.youtube.com/watch?v=MENLkK9_jUI&t=1s",
        "embedId": "MENLkK9_jUI?si=kSprc55drH-n8kAZ",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 1,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Die Woidler",
        "location": "Im Woid",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "https://www.youtube.com/watch?v=DI_oiXGrqw4",
        "embedId": "DI_oiXGrqw4?si=SzXgQh8dUg0RkIvr",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 2,
        "title": "Sound of Munich Now 2",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 3,
        "title": "Sound of Munich Now 3",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 4,
        "title": "Sound of Munich Now 4",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 5,
        "title": "Sound of Munich Now 5",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 6,
        "title": "Sound of Munich Now 6",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 7,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 8,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 9,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 10,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 11,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 12,
        "title": "Sound of Munich Now",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
    {
        "id": 13,
        "title": "LAST ID",
        "subtitle": "",
        "description": "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet",
        "customer": "Lorem ipsum",
        "location": "",
        "comments": "Lorem ipsum dolor sit amet",
        "ytLink": "",
        "embedId": "",
        "vimLink": "https://www.vimeo.com/videoid"
    },
]

export const pressexamples = [
    {
        "id": 0,
        "title": "SZ INtervie",
        "subtitle": "Bavarian Export Session for Reeperbahnfestival 2020 - Bands: Umme Block, Elena Rud, LonelySpring, Telquist.",
        "description": "Aus Bayern nonstop in die Hansestadt und von da hinaus in die Welt: Im Rahmen der Bavarian Export Session werden vier junge Musikprojekte auf dem Reeperbahn Festival vorgestellt. Die Session wird von dem VPBy (Verband für Popkultur in Bayern e.V.) präsentiert, einem der führenden Förderer hiesiger Popmusik, der über 140 Clubs, Events, Festivals usw. unter seinem Dach vereint. In der dieses Jahr digital veranstalteten Edition präsentieren wir den passionierten Pop-Punk von Lonely Spring, inklusive Ganzkörpereinsatz, sowie den süßlich verqualmten Slackersound eines Telquist. Es gibt wunderbar sequenzierte Electronica zwischen Trip-Hop, House und Synthkaskaden von Umme Block, aber auch umwerfenden „Melancholic Love Shit“ von Songwriterin Elena Rud, deren einmalige Stimme jetzt schon auf Weltklasselevel rangiert. Also für alle was dabei. Wir empfehlen: Weizen kaltstellen und die Show genießen.",
        "customer": "VPBY - Verband für Popkultur in Bayern e.V. - www.popkultur.bayern",
        "location": "Backstage Werk München",
        "comments": "bratan",
        "ytLink": "https://www.youtube.com/watch?v=MENLkK9_jUI&t=1s",
        "embedId": "MENLkK9_jUI?si=kSprc55drH-n8kAZ",
        "vimLink": "https://www.vimeo.com/videoid"
    },
]