import React from 'react';
import { Box } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

export default function Contact() {
  return (
    <>
      <div className="headline-background">
        <h1>CONTACT</h1> <br />
        <h3>Wir freuen uns auf dich!</h3>
        <Box display="flex" mt={4}>
          <Box mx={2} >
            <FacebookIcon />
          </Box>
          <Box>
            <InstagramIcon />
          </Box>
          <Box mx={2}>
            <EmailIcon />
          </Box>
        </Box>
      </div >
      <div className="workpost">
        <Box my={4}>
          <span >Du bist neugierig geworden auf unsere Leistungen und möchtest uns gerne einmal persönlich kennenlernen? Dann nimm gerne Kontakt zu uns auf oder besuche uns in unserer Kreativwerkstatt in München. Wir freuen uns darauf, dich kennenzulernen!</span>
          <Box display="flex" justifyContent="space-between">
            <p>
              Ideal Ent. München<br />
              Regerplatz 4<br />
              81541 München<br />
              Telefon:  089 / 800 45 731<br />
              E-Mail: info@idealent.de<br />
            </p>
            <iframe 
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2663.2707111172635!2d11.585066077174252!3d48.12430257124205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ddf7a6b2498ff%3A0xd05f03262c5309f7!2sRegerpl.%204%2C%2081541%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1709240342352!5m2!1sde!2sde" 
            width="400" 
            height="350" 
            style={{ border: 0 }}
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade" 
            />
              
          </Box>
          <p>Als Crewmitglieder bieten wir unsere vielfältigen Fähigkeiten in den Bereichen Produktion, Regie, Kamera, Ton, Beleuchtung und vielem mehr an. Wir haben an einer Vielzahl von Film- & Liveprojekten gearbeitet und sind bereit, unsere Expertise und Kreativität in dein nächstes Projekt einzubringen.
          </p>
          <p>Besuche unsere Crew United Seite, um mehr über uns zu erfahren, unsere bisherigen Arbeiten anzusehen und Kontakt mit uns aufzunehmen. Wir freuen uns darauf, dich kennenzulernen!</p>
        </Box>
      </div>
    </>
  );
}
