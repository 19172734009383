import React from 'react';
import logo from './logo.svg';
import './App.css';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, Box, ListItem, ListItemText } from '@mui/material';
import { createBrowserRouter, RouterProvider, Link, Routes, Route, Outlet } from 'react-router-dom';
import Home from './routes/home';
// import Post, { loader as postLoader } from './routes/post';
import ErrorPage from './error-page';
import NavBar from './NavBar';
import Footer from './Footer';
import Work from './routes/work';
import WorkPost from './routes/workpost';
import Studio from './routes/studio/studio';
import ScrollToTop from './ScrollToTop';
import Clients from './routes/clients';
import Press from './routes/press';
import MakingOf from './routes/makingof';
import Contact from './routes/contact';
import Impressum from './routes/impressum';
import Test from './routes/test';
// Definiere die Seitenkomponenten
// const Home = () => <Typography variant="h4">Home Page</Typography>;
// const References = () => <Typography variant="h4">References Page</Typography>;
// const Contact = () => <Typography variant="h4">Contact Page</Typography>;



const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: (
  //     <React.StrictMode>
  //       <div>
  //       <NavBar />
  //         {/* <Home /> */}
  //       </div>
  //     </React.StrictMode>
  //   ),
  //   errorElement: <ErrorPage />,
  //   loader: homeLoader,
  // },
  // {
  //   path: '/work',
  //   element: (
  //     <React.StrictMode>
  //       <div>
  //       <NavBar />
  //         <Work />
  //       </div>
  //     </React.StrictMode>
  //   ),
  //   errorElement: <ErrorPage />,
  //   loader: workLoader,
  // },
  {
    path: '/',
    element: <NavBarWrapper />,
    children: [
      {
        path: "/", // yes, again
        element: <Home />
      },
      {
        path: "/studio",
        element: <Studio />
      },
      {
        path: "/work",
        element: <Work />
      },
      {
        path: "/test",
        element: <Test />
      },
      {
        path: '/work/:postId',
        element: <WorkPost />,
        // errorElement: <ErrorPage />,
        // loader: postLoader,
      },
      {
        path: "/clients",
        element: <Clients />
      },
      {
        path: "/press",
        element: <Press />
      },
      {
        path: "/making_of",
        element: <MakingOf />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/impressum",
        element: <Impressum />
      },
    ],
    errorElement: <ErrorPage />,
  }
  // {
  //   path: 'posts/:postId',
  //   element: <Post />,
  //   errorElement: <ErrorPage />,
  //   loader: postLoader,
  // },
]);

function NavBarWrapper() {
  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
      <ScrollToTop />
    </div>
  )
};

function App() {
  return (
    <RouterProvider onUpdate={() => window.scrollTo(0, 0)} router={router} />
  );
}

export default App;
